import {
  type ChangeEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ClickAwayListener,
  InputAdornment,
  InputBase,
  inputBaseClasses,
  ListSubheader,
  MenuList,
  Paper,
  Popper,
  styled,
} from '@src/ui/material-ui';
import { black, neutralDefault, neutralHigh, white } from '@src/support/colors';
import { Button, FontAwesomeIcon } from '@src/ui';
import { MultiSelectItem, type SelectItem } from './multi-select-item';

const StyledInput = styled(InputBase)(() => ({
  [`&.${inputBaseClasses.root}`]: {
    width: '100%',
  },
  [`& .${inputBaseClasses.input}`]: {
    borderRadius: 4,
    position: 'relative',
    border: `1px solid ${neutralDefault}`,
    fontSize: 16,
    width: '100%',
    padding: '10px 36px 10px 12px',
  },
}));

type SelectedValues = { [key: string]: boolean };

type Props = {
  items: SelectItem[];
  initialSelectedItems?: SelectItem[];
  filterItems: boolean;
  focusedPlaceholder?: string;
  placeholder?: string;
  defaultSubheader?: string;
  onApply?: (items: SelectItem[]) => void;
  onClear?: () => void;
  onClose?: (items: SelectItem[]) => void;
  onSearch?: (term: string) => void;
  onSelect?: (items: SelectItem[], selected: boolean) => void;
  disabled?: boolean;
  allItemsLabel?: string;
  flatList?: boolean;
  selectChildChildren?: boolean;
};

const removeDuplicated = (acc: SelectItem[], item: SelectItem) => {
  if (!acc.some(i => i.value === item.value && i.type === item.type)) {
    acc.push(item);
  }

  return acc;
};

const sortList = (a: SelectItem, b: SelectItem) =>
  a.textTerm > b.textTerm ? 1 : b.textTerm > a.textTerm ? -1 : 0;

export const AutoCompleteMultiSelect = ({
  items,
  initialSelectedItems = [],
  defaultSubheader,
  filterItems = true,
  focusedPlaceholder,
  onApply,
  onClear,
  onClose,
  onSearch,
  onSelect,
  disabled,
  allItemsLabel = 'All items',
  flatList = false,
  selectChildChildren = false,
  placeholder,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedValuesMap, setSelectedValuesMap] = useState<SelectedValues>(
    initialSelectedItems
      .map(item => item.value)
      .reduce(
        (map, value) => ({
          ...map,
          [value]: true,
        }),
        {}
      )
  );
  const [shouldOpen, setShouldOpen] = useState<boolean>(false);

  const anchorRef = useRef<HTMLDivElement>(null);

  const selectedItems = useMemo(() => {
    const validInitialSelectedItems = initialSelectedItems.filter(
      item => selectedValuesMap[item.value]
    );

    const selectedParentItems = items.filter(
      item => selectedValuesMap[item.value]
    );

    const notSelectedParentItems = items.filter(
      item => !selectedValuesMap[item.value]
    );

    const childrenItems = notSelectedParentItems
      .map(item => item.children)
      .flat();

    const selectedChildItems = childrenItems.filter(
      item => item && selectedValuesMap[item?.value]
    ) as SelectItem[];

    if (flatList) {
      return [
        ...validInitialSelectedItems,
        ...selectedParentItems,
        ...selectedChildItems,
        ...selectedParentItems
          .map(i => i.children || [])
          .flat()
          .filter(item => item && selectedValuesMap[item?.value]),
      ]
        .reduce<SelectItem[]>(removeDuplicated, [])
        .sort(sortList);
    } else {
      return [
        ...validInitialSelectedItems,
        ...selectedParentItems,
        ...selectedChildItems,
      ]
        .reduce<SelectItem[]>(removeDuplicated, [])
        .sort(sortList);
    }
  }, [initialSelectedItems, flatList, selectedValuesMap, items]);

  const clearedFlatListItems = useMemo(
    () =>
      [...items, ...items.map(i => i.children || []).flat()].reduce<
        SelectItem[]
      >((acc, item) => {
        if (!acc.some(i => i.value === item.value && i.type === item.type)) {
          acc.push(item);
        }

        return acc;
      }, []),
    [items]
  );

  const filteredItems = useMemo(() => {
    if (filterItems && searchTerm) {
      return clearedFlatListItems
        .filter(item =>
          searchTerm && item.textTerm
            ? item.textTerm.match(new RegExp(searchTerm, 'i'))
            : true
        )
        .sort(sortList);
    }

    return items;
  }, [searchTerm, items, filterItems, clearedFlatListItems]);

  const open = useMemo(() => {
    if (shouldOpen && filteredItems.length > 0) {
      return true;
    }

    if (shouldOpen && selectedItems.length > 0) {
      return true;
    }

    return false;
  }, [shouldOpen, filteredItems, selectedItems]);

  useEffect(() => {
    setSelectedValuesMap(
      initialSelectedItems
        .map(item => item.value)
        .reduce(
          (map, value) => ({
            ...map,
            [value]: true,
          }),
          {}
        )
    );
  }, [initialSelectedItems]);

  const handleApply = () => {
    if (onApply) {
      onApply(selectedItems);
    }

    setSearchTerm('');
    setShouldOpen(false);
  };

  const handleClose = () => {
    if (open) {
      if (onClose) {
        onClose(selectedItems);
      }
    }

    setSearchTerm('');
    setShouldOpen(false);
  };

  const handleClear = () => {
    if (onClear) {
      onClear();
    }

    setSearchTerm('');
    setSelectedValuesMap({});
  };

  const handleSearch: ChangeEventHandler<HTMLInputElement> = e => {
    if (onSearch) {
      onSearch(e.currentTarget.value);
    }
    setSearchTerm(e.currentTarget.value);
  };

  const handleSelect = (
    newSelectedItems: SelectItem[],
    selected: boolean,
    parentItem?: SelectItem
  ) => {
    const childChildren: SelectItem[] = [];

    if (selectChildChildren && selected) {
      newSelectedItems.map(child => {
        const parent = items.find(
          i => i.value === child.value && i.type === child.type
        );

        if (parent) {
          childChildren.push(...(parent.children || []));
        }
      });
    }

    const newSelectedValuesMap = [...newSelectedItems, ...childChildren.flat()]
      .map(item => item.value)
      .reduce(
        (map, value) => ({
          ...map,
          [value]: selected,
        }),
        {}
      );

    const newValues: SelectedValues = {
      ...selectedValuesMap,
      ...newSelectedValuesMap,
    };

    const allChildSelected = parentItem
      ? (parentItem?.children || [])
          .map(item => item.value)
          .every(i => newValues[i] && newValues[i] === true)
      : false;

    const parentMap =
      parentItem &&
      [parentItem]
        .map(item => item.value)
        .reduce(
          (map, value) => ({
            ...map,
            [value]: allChildSelected,
          }),
          {}
        );

    setSelectedValuesMap({
      ...newValues,
      ...parentMap,
    });

    if (onSelect) {
      const selectParent = allChildSelected && parentItem ? [parentItem] : [];

      onSelect(
        [...newSelectedItems, ...childChildren, ...selectParent].reduce<
          SelectItem[]
        >(removeDuplicated, []),
        selected
      );
    }
  };

  const isSelected = (value: string) => !!selectedValuesMap[value];

  const subheader: string = useMemo(() => {
    if (searchTerm) {
      return 'Search Results';
    }

    if (defaultSubheader) {
      return defaultSubheader;
    }

    return allItemsLabel;
  }, [defaultSubheader, searchTerm, allItemsLabel]);

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <div className="AutoCompleteMultiselectContainer">
          <StyledInput
            value={searchTerm}
            ref={anchorRef}
            placeholder={shouldOpen ? focusedPlaceholder : placeholder}
            onChange={handleSearch}
            onFocus={() => setShouldOpen(true)}
            disabled={disabled}
            endAdornment={
              <InputAdornment
                position="end"
                sx={{
                  position: 'relative',
                  left: '-32px',
                  width: 0,
                  margin: 0,
                }}
              >
                <FontAwesomeIcon icon={['fal', 'magnifying-glass']} size="sm" />
              </InputAdornment>
            }
          />
          <Popper
            sx={{ zIndex: 99999, maxWidth: '100%' }}
            open={open}
            anchorEl={anchorRef.current}
            placement="bottom-start"
            disablePortal
          >
            <Paper sx={{ zIndex: 99999 }}>
              <div className="MenuContainer">
                {filteredItems.length > 0 && (
                  <MenuList
                    autoFocusItem={false}
                    subheader={
                      <ListSubheader sx={{ background: white }}>
                        {subheader}
                      </ListSubheader>
                    }
                    sx={{
                      overflowX: 'hidden',
                      overflowY: 'scroll',
                      maxHeight: '230px',
                    }}
                  >
                    {filteredItems.map(item => (
                      <MultiSelectItem
                        flatList={flatList && !!searchTerm && filterItems}
                        key={`${item.value}_${item.type}`}
                        item={item}
                        onSelect={handleSelect}
                        isSelected={isSelected}
                      />
                    ))}
                  </MenuList>
                )}
                {selectedItems.length > 0 && (
                  <MenuList
                    autoFocusItem={false}
                    subheader={
                      <ListSubheader sx={{ background: white }}>
                        Selected (
                        {
                          Object.values(selectedValuesMap).filter(Boolean)
                            .length
                        }
                        )
                      </ListSubheader>
                    }
                    sx={{
                      overflowX: 'hidden',
                      overflowY: 'scroll',
                      maxHeight: '230px',
                    }}
                  >
                    {selectedItems.map(item => (
                      <MultiSelectItem
                        flatList={flatList}
                        key={`${item.value}_${item.type}`}
                        item={item}
                        onSelect={handleSelect}
                        isSelected={isSelected}
                      />
                    ))}
                  </MenuList>
                )}
              </div>
              <Paper
                className="Footer"
                sx={{
                  display: 'flex',
                  padding: '16px',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  onClick={handleClear}
                  disabled={selectedItems.length === 0}
                  style={{
                    color: selectedItems.length === 0 ? neutralHigh : black,
                    marginRight: '32px',
                  }}
                  variant="link"
                  underline
                >
                  Clear
                </Button>
                <Button
                  onClick={handleApply}
                  style={{
                    minWidth: '7.5em',
                    paddingBottom: '0.75em',
                    paddingTop: '0.75rem',
                    border: 'none',
                  }}
                  size="medium"
                  color="primary"
                  shape="rounded"
                >
                  Apply Filter
                </Button>
              </Paper>
            </Paper>
          </Popper>
        </div>
      </ClickAwayListener>
      <style jsx global>
        {`
          .MenuContainer {
            z-index: 1;
          }

          .MenuContent {
            display: flex;
            flex-direction: column;
            max-height: 400px;
          }
        `}
      </style>
    </>
  );
};
