import type { PropsWithChildren } from 'react';

import { DESKTOP_MIN_WIDTH } from '@src/constants/breakpoints';
import clsx from 'clsx';

export function DirectoryNavigation(
  props: PropsWithChildren<{ className?: string }>
) {
  return (
    <div className={clsx('DirectoryNavigation', props.className)}>
      {props.children}
      <style jsx>{`
        .DirectoryNavigation {
          font-size: 1rem;
          padding: 1.25em;
        }

        @media (min-width: ${DESKTOP_MIN_WIDTH}) {
          .DirectoryNavigation {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
          }
        }

        @media screen and (min-width: 64em) {
          .DirectoryNavigation {
            padding: 1.25em 0;
          }
        }
      `}</style>
    </div>
  );
}
