import { black, neutralLower, primary01, white } from '@src/support/colors';
import clsx from 'clsx';

type CountBadgeColors = 'default' | 'dark' | 'gray';

type Props = {
  count?: number;
  color?: CountBadgeColors;
};

export function CountBadge({ count = 0, color = 'default' }: Props) {
  if (count === 0) return <></>;

  return (
    <span
      className={clsx('CountBadge', {
        'CountBadge--color-default': color === 'default',
        'CountBadge--color-dark': color === 'dark',
        'CountBadge--color-gray': color === 'gray',
      })}
    >
      {count}

      <style jsx>{`
        .CountBadge {
          font-size: 0.875em;
          padding: 0.14285714285714285em 0.35714285714285715em;
          margin-left: 0.7142857142857143em;
          border-radius: 1.1428571428571428em;
          font-weight: 700;
        }

        .CountBadge--color-default {
          background-color: ${primary01};
        }

        .CountBadge--color-dark {
          background-color: ${black};
          color: ${white};
        }

        .CountBadge--color-gray {
          background-color: ${neutralLower};
          font-weight: 400;
        }
      `}</style>
    </span>
  );
}
