import {
  Checkbox,
  InputBase,
  inputBaseClasses,
  ListItemText,
  MenuItem,
  Select,
  type SelectChangeEvent,
  styled,
} from '@src/ui/material-ui';
import { neutralHigher, semanticInfoLow } from '@src/support/colors';

const BootstrapInput = styled(InputBase)(() => ({
  [`& .${inputBaseClasses.input}`]: {
    borderRadius: '0.25em',
    position: 'relative',
    border: `0.0625em solid ${neutralHigher}`,
    padding: '0.75em 0.625em',
    minWidth: '7.5em',
    maxHeight: '2.5em',
    fontSize: '0.875em',
    '&:focus': {
      borderRadius: 4,
      borderColor: semanticInfoLow,
      boxShadow: '0 0 0 0.125rem rgba(0,123,255,.25)',
    },
  },
}));

type Item = {
  value: string;
  displayName: string;
};

type DropdownCheckboxType = {
  handleChange: (event: SelectChangeEvent<string[]>) => void;
  selectedValue: string[];
  placeholder: string;
  items: Item[];
};

export const DropdownCheckbox = ({
  selectedValue,
  placeholder,
  items,
  handleChange,
}: DropdownCheckboxType) => {
  const renderValue = (selected: string[]) => {
    if ((selected as string[]).length === 0) {
      return <div style={{ color: neutralHigher }}>{placeholder}</div>;
    }

    if ((selected as string[]).length > 1) {
      return `${(selected as string[]).length} Shifts Selected`;
    }

    return (selected as string[]).join(', ');
  };

  return (
    <Select
      multiple
      fullWidth
      value={selectedValue}
      onChange={handleChange}
      displayEmpty
      variant="standard"
      input={<BootstrapInput />}
      style={{ maxHeight: '2.5em' }}
      renderValue={selected => renderValue(selected)}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
    >
      {items.map(({ value, displayName }) => (
        <MenuItem key={value} value={value}>
          <Checkbox checked={selectedValue.indexOf(value) > -1} />
          <ListItemText primary={displayName} />
        </MenuItem>
      ))}
    </Select>
  );
};
