import { type PropsWithChildren, useMemo, useState } from 'react';
import { DSBodyText, DSHeader } from '@src/ui/jobs-ds/common/typography';
import { FontAwesomeIcon, Modal } from '@src/ui';
import type { JobsPageSearchParams } from '@src/containers/jobs-page/types';

import type { RoleItem } from './role_item';
import { black } from '@src/support/colors';

type JobsFilterMobileProps = {
  initialParams: JobsPageSearchParams;
  isMobile?: boolean;
  roles: RoleItem[];
};

export const JobsFilterMobile = ({
  isMobile,
  initialParams,
  children,
  roles,
}: PropsWithChildren<JobsFilterMobileProps>) => {
  const [isModalOpen, setModal] = useState(false);
  const shiftLabel = useMemo(() => {
    if (initialParams.shiftTypes.length === 4) {
      return 'All Shifts';
    }

    if (initialParams.shiftTypes.length > 1) {
      return `${initialParams.shiftTypes.length} Selected`;
    }

    return initialParams.shiftTypes[0];
  }, [initialParams]);
  const roleLabel = initialParams.roleSlug
    ? roles.find(r => r.slug === initialParams.roleSlug)?.filterName
    : 'All Roles';
  const specialtyUnitLabel =
    initialParams?.specialtyUnits?.length === 1
      ? initialParams.specialtyUnits[0].displayName
      : (initialParams?.specialtyUnits?.length ?? 0) > 1
        ? `${initialParams?.specialtyUnits?.length} Specialties/Units`
        : 'All Specialties/Units';

  const locationString =
    initialParams.locationStrings.length === 1
      ? initialParams.locationStrings[0]
      : initialParams.locationStrings.length > 1
        ? `${initialParams.locationStrings.length} Locations`
        : 'All Locations';

  if (!isMobile) return <>{children}</>;

  return (
    <>
      <div className="JobsFilter--Mobile" onClick={() => setModal(true)}>
        <div className="JobsFilter--Mobile__container">
          <div className="JobsFilter--Mobile__title">
            <DSBodyText className="JobsFilter--Mobile__title-unit" underline>
              {specialtyUnitLabel}
            </DSBodyText>
            <DSBodyText className="JobsFilter--Mobile__title-role" underline>
              &nbsp;- {roleLabel}
            </DSBodyText>
            <FontAwesomeIcon
              className="JobsFilter--Mobile__title-icon"
              color={black}
              icon={['far', 'sliders-v']}
            />
          </div>
          <div className="JobsFilter--Mobile__bottom">
            <DSBodyText underline small bold>
              Location: {locationString}
            </DSBodyText>
            <DSBodyText underline small bold>
              Shift: {shiftLabel}
            </DSBodyText>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        styles={{ content: { width: '100%', padding: '1.875rem 1.5rem' } }}
      >
        <div className="JobsFilter--Modal">
          <div
            className="JobsFilter--Modal-close"
            onClick={() => setModal(false)}
          >
            <FontAwesomeIcon icon={['fas', 'times']} />
          </div>
          <div className="JobsFilter--Modal-title">
            <DSHeader size={1}>Job Finder</DSHeader>
          </div>
          {children}
        </div>
      </Modal>
      <style jsx>{`
        .JobsFilter--Mobile {
          padding: 1rem 1.25rem;
          width: 100%;
        }
        .JobsFilter--Mobile:hover {
          cursor: pointer;
        }
        .JobsFilter--Mobile__bottom {
          display: flex;
          gap: 1rem;
        }
        .JobsFilter--Mobile__container {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        }
        .JobsFilter--Mobile__title {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        :global(.JobsFilter--Mobile__title-unit) {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        :global(.JobsFilter--Mobile__title-role) {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          margin-right: auto;
          flex: 1;
        }
        :global(.JobsFilter--Mobile__title-icon) {
          margin-left: 0.5rem;
        }
        .JobsFilter--Modal {
          height: 100vh;
        }
        .JobsFilter--Modal-close {
          display: flex;
          justify-content: flex-end;
          cursor: pointer;
          padding: 0.5rem;
        }
        .JobsFilter--Modal-title {
          margin-bottom: 2.125rem;
        }
      `}</style>
    </>
  );
};
