import { useEffect, useMemo, useState } from 'react';

import { isEqual } from 'lodash';
import {
  DESKTOP_MIN_WIDTH,
  MOBILE_MAX_WIDTH,
} from '@src/constants/breakpoints';
import { Button } from '@src/ui';
import { JobsDropdownSelect } from '../jobs-dropdown-select';
import { DropdownCheckbox } from './dropdown-checkbox';
import { JobsLocationFilter } from './jobs-location-filter';
import type { JobsPageSearchParams } from '@src/containers/jobs-page/types';
import type {
  Filter,
  JobsPageSpecialtyUnitFilter,
  TrustedJobUnit,
} from '@src/queries/jobs-page-content-query';
import type { ShiftType } from '@generated/graphql';
import { useIsMobile } from '@src/hooks/use-is-mobile';
import clsx from 'clsx';
import { getJobsCount, getStateName } from '@src/containers/jobs-page/utils';
import { CountBadge } from '@src/components/count-badge/count-badge';
import { JobsFilterMobile } from './jobs-filter-mobile';
import { JOB_URL_TYPE } from '@src/constants';
import { AutoCompleteMultiSelect } from '@src/ui/auto-complete-multi-select';
import type { SelectItem } from '@src/ui/auto-complete-multi-select/multi-select-item';
import type { RoleItem } from './role_item';
import { neutralLower, white } from '@src/support/colors';

const shifts = [
  {
    value: 'day',
    displayName: 'Day',
  },
  {
    value: 'evening',
    displayName: 'Evening',
  },
  {
    value: 'night',
    displayName: 'Night',
  },
  {
    value: 'variable',
    displayName: 'Variable',
  },
];

export type SpecialtyUnit = {
  slug: string;
  displayName?: string;
  isParent?: boolean;
  unitSlugs?: string[];
  __typename: string;
};

type Props = {
  specialtiesUnits: JobsPageSpecialtyUnitFilter[];
  roles: RoleItem[];
  initialParams: JobsPageSearchParams;
  onSubmit: (newSearchFilters: JobsPageSearchParams) => void;
};

export const buildSpecialtyUnitPlaceholder = (specialtyUnits: SelectItem[]) => {
  if (specialtyUnits?.length === 0) {
    return 'All Specialties/Units';
  } else if (specialtyUnits?.length === 1) {
    return specialtyUnits[0].textTerm;
  } else {
    return `${
      (specialtyUnits || []).reduce<SelectItem[]>((acc, item) => {
        if (!acc.some(i => i.value === item.value && i.type === item.type)) {
          acc.push(item);
        }

        return acc;
      }, []).length
    } Specialties/Units selected`;
  }
};

const filterChildren = (
  filters: Filter[],
  specialtyUnit: JobsPageSpecialtyUnitFilter,
  jobUrlType?: JOB_URL_TYPE | ''
) => {
  const otherOption = buildOtherOption(specialtyUnit);

  if (jobUrlType === JOB_URL_TYPE.TravelNurse) {
    const activeFilters = filters.filter(
      item => item.nursingJobsCount && item.nursingJobsCount > 0
    );

    if (activeFilters.length > 0) {
      return [...filters, otherOption].filter(
        item => item.nursingJobsCount && item.nursingJobsCount > 0
      );
    }
  } else if (jobUrlType === JOB_URL_TYPE.TravelAllied) {
    const activeFilters = filters.filter(
      item => item.alliedJobsCount && item.alliedJobsCount > 0
    );

    if (activeFilters.length > 0) {
      return [...filters, otherOption].filter(
        item => item.alliedJobsCount && item.alliedJobsCount > 0
      );
    }
  }

  return [];
};

const buildOtherOption = (item: JobsPageSpecialtyUnitFilter): Filter => {
  const filterUnits = item.filters
    .map(i => i.trustedJobUnits || [i])
    .flat()
    .filter(i => i?.__typename === 'TrustedJobUnit');

  const trustedJobUnits = item.trustedJobUnits.reduce<TrustedJobUnit[]>(
    (acc, unit) => {
      if (!filterUnits.some(i => i.slug === unit.slug)) {
        acc.push(unit);
      }

      return acc;
    },
    []
  );

  const nursingCount = trustedJobUnits.reduce(
    (acc, i) => acc + (i.nursingJobsCount || 0),
    0
  );
  const alliedCount = trustedJobUnits.reduce(
    (acc, i) => acc + (i.alliedJobsCount || 0),
    0
  );

  return {
    id: item.id,
    slug: `${item.slug}-others`,
    displayName: `${item.name} - Others`,
    trustedJobUnits: trustedJobUnits,
    alliedJobsCount: alliedCount,
    nursingJobsCount: nursingCount,
    __typename: 'others',
  };
};

export const buildSelectedItems = (specialtyUnits: SpecialtyUnit[]) =>
  specialtyUnits.map(
    item =>
      ({
        label: <>{item.displayName}</>,
        textTerm: item.displayName,
        value: item.slug,
        type: item.__typename,
        parent: item.isParent,
      }) as SelectItem
  );

export const getSelectedSpecialtyUnits = (
  specialtiesUnits: JobsPageSpecialtyUnitFilter[],
  selectedUnits: SelectItem[]
) =>
  [
    ...specialtiesUnits,
    ...specialtiesUnits.map(item => [...item.filters, buildOtherOption(item)]),
  ]
    .flat()
    .filter(specialty =>
      selectedUnits.some(
        item =>
          item.value === specialty.slug && item.type === specialty.__typename
      )
    )
    .map(item => {
      const selectedItem = selectedUnits.find(
        i => i.value === item.slug && i.type === item.__typename
      );
      const isParent =
        item.__typename === 'JobsSpecialty' && selectedItem?.parent;

      return {
        slug: item.slug,
        displayName: selectedItem?.textTerm,
        unitSlugs: ['JobsSpecialty', 'others'].includes(item.__typename)
          ? item.trustedJobUnits?.map(unit => unit.slug)
          : [item.slug],
        __typename: item.__typename,
        isParent: isParent,
      };
    })
    .reduce<SpecialtyUnit[]>((acc, currentValue) => {
      if (
        !acc.some(
          item =>
            item.slug === currentValue.slug &&
            item.__typename === currentValue.__typename
        )
      ) {
        acc.push(currentValue);
      }

      return acc;
    }, []);

export const buildSpecialtyUnitItems = (
  specialtiesUnits: JobsPageSpecialtyUnitFilter[],
  jobType?: JOB_URL_TYPE | ''
) =>
  specialtiesUnits
    .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    .map(specialtyUnit => ({
      label: (
        <>
          {specialtyUnit.name}{' '}
          <CountBadge
            color="gray"
            count={getJobsCount(specialtyUnit, jobType)}
          />
        </>
      ),
      value: specialtyUnit.slug,
      type: specialtyUnit.__typename,
      textTerm: specialtyUnit.name,
      parent: true,
      children:
        (specialtyUnit.filters || []).length > 0
          ? filterChildren(specialtyUnit.filters, specialtyUnit, jobType).map(
              filter => ({
                label: (
                  <>
                    {filter.displayName}{' '}
                    <CountBadge
                      color="gray"
                      count={getJobsCount(filter, jobType)}
                    />
                  </>
                ),
                parent: false,
                value: filter.slug,
                textTerm: filter.displayName,
                type: filter.__typename,
              })
            )
          : [],
    }));

export function JobsFilters({
  initialParams,
  specialtiesUnits = [],
  roles = [],
  onSubmit,
}: Props) {
  const isMobile = useIsMobile();
  const [mobile, setMobile] = useState(false);
  const jobType = initialParams.jobType;
  const [role, setRole] = useState(initialParams.roleSlug || '');
  const [shift, setShift] = useState<ShiftType[]>(
    initialParams.shiftTypes || []
  );
  const [specialtyUnits, setSpecialtyUnits] = useState<SelectItem[]>(
    initialParams.specialtyUnits
      ? buildSelectedItems(initialParams.specialtyUnits)
      : []
  );
  const [locationStrings, setLocationStrings] = useState<string[]>(
    initialParams.locationStrings
  );

  // Mobile render on client-side
  useEffect(() => {
    setMobile(isMobile);
  }, [isMobile]);
  const rolesItems = useMemo(
    () => [
      { value: '', displayName: 'All Roles' },
      ...roles
        .map(({ slug, filterName }) => ({
          value: slug || '',
          displayName: filterName,
        }))
        .sort((a, b) =>
          a.displayName > b.displayName
            ? 1
            : b.displayName > a.displayName
              ? -1
              : 0
        ),
    ],
    [roles]
  );

  const specialtyUnitItems = useMemo(
    () => buildSpecialtyUnitItems(specialtiesUnits, jobType),
    [specialtiesUnits, jobType]
  );

  const specialtyUnitsPlaceholder = useMemo(() => {
    return buildSpecialtyUnitPlaceholder(specialtyUnits);
  }, [specialtyUnits]);

  useEffect(() => {
    setShift(initialParams.shiftTypes);
    setRole(initialParams.roleSlug || '');
    setSpecialtyUnits(
      initialParams.specialtyUnits
        ? buildSelectedItems(initialParams.specialtyUnits)
        : []
    );
  }, [initialParams, specialtiesUnits]);

  useEffect(() => {
    if (initialParams.locationStrings) {
      setLocationStrings(
        initialParams.locationStrings.map(locationString => {
          const [locationA, locationB] = locationString.split(',');

          if (locationA && !locationB) {
            return getStateName(locationA) || locationA;
          } else {
            return locationString;
          }
        })
      );
    }
  }, [initialParams]);

  const handleFormSubmit = () => {
    if (
      initialParams.roleSlug !== role ||
      initialParams?.locationStrings.toString() !==
        locationStrings.toString() ||
      (specialtyUnits || [])?.length > 0 ||
      !isEqual(initialParams?.shiftTypes, shift)
    ) {
      const selectedSpecialtyUnits = getSelectedSpecialtyUnits(
        specialtiesUnits,
        specialtyUnits
      );

      onSubmit({
        roleSlug: role,
        specialtyUnits: selectedSpecialtyUnits || undefined,
        shiftTypes: shift,
        locationStrings: locationStrings,
      });
    }
  };

  const addSpecialtyUnit = (values: SelectItem[]) => {
    setSpecialtyUnits(prevState => [...values, ...prevState]);
  };

  const removeSpecialtyUnit = (values: SelectItem[]) => {
    setSpecialtyUnits(prevState =>
      prevState.filter(
        value =>
          !values.some(
            item => item.value === value.value && item.type === value.type
          )
      )
    );
  };

  const handleApplyLocationFilters = (selectedLocations: string[]) => {
    setLocationStrings(selectedLocations);

    const selectedSpecialtyUnits = getSelectedSpecialtyUnits(
      specialtiesUnits,
      specialtyUnits
    );

    onSubmit({
      roleSlug: role,
      specialtyUnits: selectedSpecialtyUnits || undefined,
      shiftTypes: shift,
      locationStrings: selectedLocations,
    });
  };

  return (
    <div
      className={clsx('JobsFilter', 'JobsFilter-sticky', {
        'JobsFilter-sticky-mobile': mobile,
      })}
    >
      <JobsFilterMobile
        initialParams={initialParams}
        isMobile={mobile}
        roles={roles}
      >
        <div className="JobsFilterItem JobsFilterSelect">
          <JobsDropdownSelect
            handleChange={event => setRole(event.target.value as string)}
            items={rolesItems}
            value={role}
          />
        </div>

        <div className="JobsFilterItem JobsFilterDropdown">
          <AutoCompleteMultiSelect
            initialSelectedItems={specialtyUnits}
            allItemsLabel="All Specialties/Units"
            filterItems={true}
            flatList={true}
            selectChildChildren={true}
            items={specialtyUnitItems}
            placeholder={specialtyUnitsPlaceholder}
            onApply={handleFormSubmit}
            onClear={() => setSpecialtyUnits([])}
            onSelect={(items, selected) => {
              if (selected) {
                addSpecialtyUnit(items);
              } else {
                removeSpecialtyUnit(items);
              }
            }}
          />
        </div>

        <div className="JobsFilterItem">
          <JobsLocationFilter
            initialLocations={initialParams.locationStrings}
            onSubmit={handleApplyLocationFilters}
          />
        </div>

        <div className="JobsFilterItem JobsFilterSelect">
          <DropdownCheckbox
            placeholder="Shift(s)"
            items={shifts}
            selectedValue={shift}
            handleChange={event => setShift(event.target.value as ShiftType[])}
          />
        </div>

        <div className="JobsFilterItem JobsFilterButtonItem">
          <Button
            style={{
              minWidth: '7.5em',
              paddingBottom: '0.75em',
              paddingTop: '0.75rem',
              border: 'none',
              width: '100%',
            }}
            size="medium"
            color="primary"
            shape="rounded"
            onClick={handleFormSubmit}
          >
            Search
          </Button>
        </div>
      </JobsFilterMobile>
      <style jsx>{`
        .JobsFilter {
          font-size: 1rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 1.25em 0;
        }
        .JobsFilter-sticky {
          position: sticky;
          top: 0;
          background: ${neutralLower};
          z-index: 1;
        }
        .JobsFilter-sticky-mobile {
          top: 86px;
          background: ${white};
          border-bottom: 0.0625em solid ${neutralLower};
        }
        .JobsFilterSelect {
          max-height: 2.5em;
        }

        .JobsFilterDropdown {
          max-height: 2.5em;
          width: 12.5em;
          min-width: 12.5rem;
        }

        .JobsFilterItem {
          width: 20%;
        }

        .JobsFilterButtonItem {
          width: 10%;
        }

        @media (max-width: ${MOBILE_MAX_WIDTH}) {
          .JobsFilter {
            padding: 1.25em;
            flex-direction: column;
          }

          .JobsFilterItem {
            width: 100%;
            min-width: 100%;
            margin: 0.625em 0;
          }
        }

        @media (min-width: ${DESKTOP_MIN_WIDTH}) {
          .JobsFilter {
            margin-left: auto;
            margin-right: auto;
            width: 100%;
          }

          .JobsFilterItem {
            width: 18%;
          }

          .JobsFilterButtonItem {
            width: 15%;
          }
        }
      `}</style>
    </div>
  );
}
