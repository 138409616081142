import { type ChangeEvent, type ChangeEventHandler, useState } from 'react';
import {
  Checkbox,
  Collapse,
  FormControlLabel,
  MenuItem,
} from '@src/ui/material-ui';

import { FontAwesomeIcon } from '@src/ui';

export interface SelectItem {
  label: JSX.Element;
  textTerm: string;
  value: string;
  children?: SelectItem[];
  parent?: boolean;
  type?: string;
}

type Props = {
  item: SelectItem;
  parentItem?: SelectItem;
  onSelect: (
    items: SelectItem[],
    selected: boolean,
    parentItem?: SelectItem
  ) => void;
  isSelected: (value: string) => boolean;
  flatList?: boolean;
};

export const MultiSelectItem = ({
  item,
  parentItem,
  onSelect,
  isSelected,
  flatList = false,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleSelect: ChangeEventHandler = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    onSelect(
      [
        item,
        ...(parentItem && !e.target.checked ? [parentItem] : []),
        ...(item.children || []),
      ],
      e.target.checked,
      parentItem
    );
  };

  return (
    <>
      <MenuItem
        style={{
          padding: `0 1em 0 ${parentItem ? '1.5em' : '0.375em'}`,
          lineHeight: '36px',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={isSelected(item.value)}
              onChange={handleSelect}
            />
          }
          label={item.label}
          sx={{ width: '100%' }}
        />
        <div className="MenuItemChild">
          {!flatList && item.children && item.children.length > 0 ? (
            <FontAwesomeIcon
              onClick={() => setOpen(!open)}
              icon={['fas', open ? 'chevron-up' : 'chevron-down']}
            />
          ) : null}
        </div>
      </MenuItem>
      <Collapse key={`${item.value}_container`} in={open}>
        {item.children?.map(child => (
          <MultiSelectItem
            key={child.value}
            item={child}
            parentItem={item}
            onSelect={onSelect}
            isSelected={isSelected}
          />
        ))}
      </Collapse>
      <style jsx global>
        {`
          .CollapseContainer {
            align-items: center;
            display: flex;
            justify-content: space-between;
            width: 100%;
          }

          .MenuItemChild {
            align-items: center;
            display: flex;
            justify-content: space-between;
          }
        `}
      </style>
    </>
  );
};
